import * as yup from "yup";
import generalTabSchema from "./Validation/generalTabSchema";
import productSchema from "./Validation/productSchema";
import {
  addressSchema,
  customerSchema,
  customerEditSchema,
  customerCreditSchema,
  creditSchema,
  customerTermSchema,
  customerEmployeeSchema,
  customerDocumentSchema,
  customerTypeSchema,
  customerJobTypeSchema,
} from "./Validation/customer";
import { employeeSchema, employeeEditSchema } from "./Validation/employee";
import { resetPasswordSchema } from "./Validation/auth";

// Product Form
const productsTabSchema = yup.object().shape({
  unit_id: yup.array(),
  supplier_details: yup.array().of(
    yup.lazy((value) => {
      // Create a dynamic object to hold the validation schema
      let validationObject = {};

      // Assuming unit_id is an array containing unit names like ["Box", "Each", ...]
      value?.units?.forEach((unitName) => {
        validationObject[unitName] = yup.array().of(
          yup.object().shape({
            quantity: yup.mixed().test("is-number", "Required", (value) => {
              if (value === undefined || value === null || value === "") {
                return false;
              }
              return !isNaN(value);
            }),
            cost: yup.mixed().test("is-number", "Required", (value) => {
              if (value === undefined || value === null || value === "" || value === 0) {
                return false;
              }
              return !isNaN(value);
            }),
            regular_percentage: yup
              .mixed()
              .test("is-number", "Required", (value) => {
                if (value === undefined || value === null || value === "") {
                  return false;
                }
                return !isNaN(value);
              }),
          })
        );
      });
      const notNaN = (value) => !isNaN(value);
      return yup.object().shape({
        supplier_id: yup
          .string()
          .required("Required")
          .test("is-not-nan", " Required", notNaN),
        mfg_id: yup
          .mixed()
          .test("is-same-or-not", "Not Same", function (value) {
            if (
              (value &&
                value === this.parent.supplier_sku &&
                this.parent.supplier_sku) ||
              (value === this.parent.upc_code && this.parent.upc_code)
            ) {
              return false;
            }
            return true;
          }),
        supplier_sku: yup
          .mixed()
          .test("is-same-or-not-two", "Not Same", function (value) {
            if (
              (value && value === this.parent.mfg_id && this.parent.mfg_id) ||
              (value === this.parent.upc_code && this.parent.upc_code)
            ) {
              return false;
            }
            return true;
          }),
        upc_code: yup
          .mixed()
          .test("is-same-or-not-one", "Not Same", function (value) {
            if (
              (value &&
                value === this.parent.supplier_sku &&
                this.parent.supplier_sku) ||
              (value === this.parent.mfg_id && this.parent.mfg_id)
            ) {
              return false;
            }
            return true;
          }),
        ...validationObject, // Spread the dynamic validation rules
      });
    })
    // supplier_cost: yup.string().required("Required"),
    // unit_price: yup.string().required("Required"),
    // regular_price: yup.string().required("Required"),
    // })
  ),

  main_supplier_id: yup.string().test(
    "check_main",
    "required",
    function (value) {

      return value !== null && value !== '';
    }
  ),
  primarycost: yup.object().shape({
    name: yup.string().required("Unit Required"),
    // cost: yup.number().required("Cost Required"),
  }),
});

// category Form
const categorySchema = yup.object().shape({
  label: yup.string().required("Required"),
  website_id: yup.mixed().test(
    "check-required",
    "Required",
    function (value) {
      if (value === null || (Array.isArray(value) && value.length === 0) || value === undefined) {
        return false;
      }
      return true;
    }
  )
});


// Supplier Form
const supplierSchema = yup.object().shape({
  company_name: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  // last_name: yup.string().required("Required"),
  product_category: yup
    .array()
    .test("required-if-inventory", "Required", function (value) {
      return value?.length > 0 && !!value;
    }),
  email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(\.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  phone: yup
    .string()
    .required("Required")
    .test("is-valid", "Enter Valid Phone Number", (value) => {
      const numericValue = parseInt(value.split("-").join(""));
      return !isNaN(numericValue) && numericValue.toString().length === 10;
    }),
  // regular_percentage: yup
  // .string()
  // .required("Required"),
  address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
  // unit: yup.string().required("Required"),
});

// Store Form
const storeSettingsFSchema = yup.object().shape({
  website_name: yup.string().required("Required"),
  website_id: yup
    .mixed()
    .test("is-number", "Required", (value) => {
      if (value === undefined || value === null || value === "") {
        return false;
      }
      return !isNaN(value);
    })
    .required("Required"),
  website_endpoint: yup.string().required("Required").url("Enter Valid URL"),
  contact_email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  phone: yup
    .string()
    .required("Required")
    .test("is-valid", "Enter Valid Phone Number", (value) => {
      const numericValue = parseInt(value.split("-").join(""));
      return !isNaN(numericValue) && numericValue.toString().length === 10;
    }),
  website_url: yup.string().required("Required").url("Enter Valid URL"),
  website_prefix: yup.string().required("Required"),
  address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
});

// PurchaseOrderForm
const purchaseOrderSchema = yup.object().shape({
  supplier_id: yup.mixed().test("is-number", "Required", (value) => {
    if (value === undefined || value === null || value === "") {
      return false;
    }
    return !isNaN(value);
  }),
  created_by: yup.string().required("Required"),
  po_number: yup.string().required("Required"),
  confirm_by: yup.mixed().when(["approve_by"], {
    is: (approve_by) => approve_by,
    then: () => yup.string().required("Required"),
    otherwise: () => yup.mixed().notRequired(),
  }),
  delivery_type: yup.string().required("Required"),
  address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
  product_details: yup.array().of(
    yup.object().shape({
      quantity: yup
        .mixed()
        .test("is-number", "Required", (value) => {
          if (value === undefined || value === null || value === "") {
            return false;
          }
          return !isNaN(value);
        })
        .required("Required"),
    })
  ),
});
// tax popup form
const taxFormSchema = yup.object().shape({
  tax_name: yup.string().required("Required"),
  tax_amount: yup.mixed().test("is-number", "Required", (value) => {
    if (value === undefined || value === null || value === "") {
      return false;
    }
    return !isNaN(value);
  }),
});

// approve form
const approveOrderSchema = yup.object().shape({
  confirm_by: yup.string().required("Required"),
});

// Cancel purchase order
const cancelPurchaseOrderSchema = yup.object().shape({
  reason_for_cancel: yup.string().required("Required"),
  selectedRows: yup
    .object()
    .test("required-if", "Please select row for cancel", function (value) {
      return value.selectedCount > 0;
    }),
});

// Recieved Po
const recievedPoSchema = yup.object().shape({
  verified_by: yup.string().required("Required"),
});

const verifyOTPSchema = yup.object().shape({
  otp: yup.string().required("Required"),
});

const priceCategorySchema = yup.object().shape({
  categories: yup.array().of(
    yup.object().shape({
      // percentage: yup
      //   .mixed()
      //   .test("is-number", "Required", (value) => {
      //     console.log(value)
      //     if (value === undefined || value === null || value === "") {
      //       return false;
      //     }
      //     return !isNaN(value);
      //   })
      //   .required("Required"),
      // rebate_percentage: yup
      //   .mixed()
      //   .test("is-number", "Required", (value) => {
      //     if (value === undefined || value === null || value === "") {
      //       return false;
      //     }
      //     return !isNaN(value);
      //   })
      //   .required("Required"),
      category_id: yup.number().required("Required"),
    })
  ),
});


const SplitPaymentSchema = yup.object().shape({});
//refund
//Employee Form

// Promotion Form
const PromotionGeneralSchema = yup.object().shape({
  name: yup.string().required("Required"),
  start_date: yup.date().required("Required"),
  end_date: yup.date().required("Required")
    .test('is-greater', 'End date must be greater than start date', function (value) {
      const { start_date } = this.parent;
      return value >= start_date;
    }),
});
//Promotion Product
const PromotionProductSchema = yup.object().shape({
  product: yup.array().of(
    yup.object().shape({
      // percentage: yup
      //   .mixed()
      //   .test("required-if", "must be less than 100", function (value) {
      //     if (value > 100) {
      //       return false;
      //     }
      //     return !isNaN(value);
      //   })

      //   .test("is-number", "Required", (value) => {
      //     if (
      //       value === undefined ||
      //       value === null ||
      //       value === "" ||
      //       value > 100
      //     ) {
      //       return false;
      //     }
      //     return !isNaN(value);
      //   })
      //   .required("Required"),

      product_id: yup.number().required("Required"),
    })
  ),
});
//customer payment form
const PaymentSchema = yup.object().shape({
  number: yup
    .string()
    .required("Required")
    .matches(/^\d{16}$/, "Card number must be 16 digits"),

  name: yup.string().required("Required"),
  exp_date: yup
    .string()
    .required("Required")
    .matches(
      /^(0[1-9]|1[0-2])\/(2[3-9]|[3-9][0-9])$/,
      "Invalid expiration date. Example: MM/YY"
    ),
  cvc: yup
    .string()
    .required("Required")
    .matches(/^\d{3}$/, "Invalid CVC."),
  postal_code: yup.string()
    .required("Required")
    .matches(/^[A-Za-z0-9]{6}$/, "Postal code must be 6 characters long."),
});
const priceGroupGenralSchema = yup.object().shape({
  store_id: yup.array().required("Required"),
  // percentage: yup
  //   .mixed()
  //   .test("is-number", "Required", (value) => {
  //     if (value === undefined || value === null || value === "") {
  //       return false;
  //     }
  //     return !isNaN(value);
  //   })
  //   .required("Required"),
  customer_type: yup.number().required("Required"),
  name: yup.string().required("Required"),
});
const priceGroupProductSchema = yup.object().shape({
  products: yup.array().of(
    yup
      .object()
      .shape({
        // Uncomment percentage if you want this field validated as a required number.
        // percentage: yup
        //   .mixed()
        //   .test("is-number", "Required", (value) => {
        //     if (value === undefined || value === null || value === "") {
        //       return false;
        //     }
        //     return !isNaN(value);
        //   })
        //   .required("Required"),
        price: yup
          .mixed()
          .test("is-number", "Required", (value) => {
            if (value === undefined || value === null || value === "") {
              return false;
            }
            return !isNaN(value);
          })
          .required("Required"),
        product_id: yup.number().required("Required"),
      })
    // .test(
    //   "non-zero-percentage-or-rebate",
    //   "Required",
    //   (obj) => {

    //     const { percentage = 0, rebate_percentage = 0 } = obj || {};
    //     const percentageValue = percentage === "" ? 0 : percentage;
    //     const rebatePercentageValue = rebate_percentage === "" ? 0 : rebate_percentage;
    //     const isValid = percentageValue > 0 || rebatePercentageValue > 0;

    //     return isValid;
    //   }
    // )
  ),
});

const sendEmailSchema = yup.object().shape({
  customer_name: yup.string().required("Required"),
  email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(\.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  subject: yup.string().required("Required"),
  send_by: yup.string().required("Required"),
});
const ctsairpaySchema = yup.object().shape({
  email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  amount: yup.string().required("Required"),
});
//  cardAmountSchema Form
const cardAmountSchema = yup.object().shape({
  amount: yup
    .string()
    .required("Required")
    .test(
      "is-valid",
      "The amount must be greater than or equal to the minimum charge",
      function (value) {
        if (value < 1) {
          return this.createError({
            message: `The amount must be greater than or equal to the minimum charge`,
          });
        }
        return true;
      }
    ),
});
//order preparation
const orderPreparationSchema = yup.object().shape({
  verify_user: yup.string().required("Required"),
  selectedRows: yup
    .object()
    .test("required-if", "Selected row is required", function (value) {
      return value.selectedCount > 0;
    }),
});
// RETURN ORDER
const orderrReturnSchema = yup.object().shape({
  selectedRows: yup
    .object()
    .test("required-if", "Selected row is required", function (value) {
      return value.selectedCount > 0;
    }),
  reason: yup.string().required("Required"),
  receive_by: yup.string().required("Required"),
});
//order preparation
const orderAdditionalInfoSchema = yup.object().shape({
  shipping_method: yup.string().required("Required"),
  // shipping_address: yup
  //   .number()
  //   .test("required-if-inventory", "Required", function (value) {
  //     const isInventory = this.parent.shipping_method;
  //     console.log(this.parent.shipping_method, value, "shipping_method");
  //     return isInventory !== "Store pickup" ? !!value : true;
  //   }),
  // delivery_type: yup
  //   .string()
  //   .test("required-if-inventory", "Required", function (value) {
  //     const isInventory = this.parent.shipping_method;
  //     return isInventory !== "Store pickup" ? !!value : true;
  //   }),
  // ship_via: yup
  //   .number()
  //   .test("required-if-inventory", "Required", function (value) {
  //     const isInventory = this.parent.shipping_method;
  //     return isInventory !== "Store pickup" ? !!value : true;
  //   }),
  billing_address: yup.number().required("Required"),
  delivery_date: yup.string().required("Required"),
  order_taken_by: yup.string().required("Required"),
});
// Order Payment
const orderPaymentTabSchema = yup.object().shape({
  payment: yup.array().of(
    yup.object().shape({
      payment_type: yup.string(),
      over_write: yup.boolean(),
      amount: yup.mixed().test("is-number", "Required", (value) => {
        if (value === undefined || value === null) {
          return false;
        }
        return !isNaN(value);
      }),
      payment_details: yup.mixed().when(["payment_type", "amount"], {
        is: (payment_type, amount) =>
          amount > 0.0 &&
          ["cheque", "email", "ctsairpay"].includes(payment_type),
        then: () => yup.string().required("Payment Details is Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
      // payment_method: yup.string().when(["payment_type", "amount"], {
      //   is: (payment_type, amount) =>
      //     amount > 0.0 && ["card"].includes(payment_type),
      //   then: () => yup.string().required("Required"),
      //   otherwise: () => yup.mixed().notRequired(),
      // }),
      payment_method: yup.string().when(["payment_type", "amount", "payment_card_type", "validationcondition"], {
        is: (payment_type, amount, payment_card_type, validationcondition) => {

          // const conditionMet =
          //   payment_card_type === "interac_present" &&
          //   payment_type === "card" &&
          //   amount > 0.0;
          // return conditionMet;
          let conditionMet = false;
          if (validationcondition === false) {
            conditionMet = payment_card_type === "interac_present" && payment_type === "card" && amount > 0.0;
          } else if (validationcondition === true) {
            conditionMet = payment_type === "card" && amount > 0.0;
          }
          return conditionMet;
        },
        then: () => yup.string().required("Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
      over_write_by: yup
        .string()
        .when(["payment_type", "amount", "over_write"], {
          is: (payment_type, amount, over_write) =>
            amount > 0.0 && over_write && ["term"].includes(payment_type),
          then: () => yup.string().required("Required"),
          otherwise: () => yup.mixed().notRequired(),
        }),

      authorized_person: yup.number().when(["payment_type", "amount"], {
        is: (payment_type, amount) => {
          const condition = amount > 0.0 && payment_type === "temp_term";
          return condition;
        },
        then: () => yup.number().required("Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
      credit_term: yup.number().when(["payment_type", "amount"], {
        is: (payment_type, amount) => {
          const condition = amount > 0.0 && payment_type === "temp_term";
          return condition;
        },
        then: () => yup.number().required("Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
      code: yup.number().when(["payment_type", "amount"], {
        is: (payment_type, amount) => {
          const condition = amount > 0.0 && payment_type === "temp_term";
          return condition;
        },
        then: () => yup.number().required("Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
    })
  ),
});
// PurchaseOrderForm
const billFormSchema = yup.object().shape({
  supplier_id: yup.mixed().test("is-number", "Required", (value) => {
    if (value === undefined || value === null || value === "") {
      return false;
    }
    return !isNaN(value);
  }),
  // ship_via: yup.number().required("Required"),
  // buying_group: yup.number().required("Required"),
  terms: yup.string().required("Required"),
  delivery_type: yup.string().required("Required"),
  shipping_address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
  product_details: yup.array().of(
    yup.object().shape({
      quantity: yup
        .mixed()
        .test("is-number", "Required", (value) => {
          if (value === undefined || value === null || value === "") {
            return false;
          }
          return !isNaN(value);
        })
        .required("Required"),
    })
  ),
});

// New dummy Receive-payment 

const receivePaymentSchema = yup.object().shape({
  customer_id: yup.string().required("Required"),
  amounts: yup.string()
    .test("amounts", "Required", function (value) {

      if (value === 0 || value === '' || value === '0') {
        return this.createError({
          message: `Required`,
        });
      }
      return true;
    }),
  payment_type: yup.string().test("payment_type", "Required", function (value) {
    if (value === " ") {
      return this.createError({
        message: `Required`,
      });
    }
    return true;
  })
})

// const PriceGroupSchema = yup.object().shape({
//   selectedRows: yup
//     .object()
//     .test("required-if", "Selected row is required", function (value) {
//       return value.selectedCount > 0;
//     }),
//   reason: yup.string().required("Required"),
//   receive_by: yup.string().required("Required"),
// });

// Kitchen Template Validation
const kitchenTemplateSchema = yup.object().shape({
  slug: yup.string().required("Please Select Slug Corresponds To Kitchen Template")
});

// DyWall Template Validation 
const drywallTemplateSchema = yup.object().shape({
  slug: yup.string().required("Please Select Slug Corresponds To DryWall Template")
})

//Vehicle Schema
const SUPPORTED_FORMATS = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/png',
  'image/jpeg',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];
const vehicleSchema = yup.object().shape({
  vehicle_name: yup.string().required("Required"),
  // Uncomment and adjust the following if you need to validate documents
  // documents: yup.array().of(
  //   yup.object().shape({
  //     expiry_date: yup.date()
  //       .transform((value, originalValue) => {
  //         return originalValue === "" ? null : value;
  //       })
  //       .required('Expiry Date is required'),
  //     document: yup.mixed()
  //       .required('Document is required')
  //       .test(
  //         'fileFormat',
  //         'Unsupported file format',
  //         value => value && SUPPORTED_FORMATS.includes(value.type)
  //       ),
  //   })
  // ),
});

const brandSchema = yup.object().shape({
  brand_name: yup.string().required("Required"),
  brand_model: yup.string().required("Required"),
  website_id: yup.mixed().test(
    "check-required",
    "Required",
    function (value) {
      if (value === null || (Array?.isArray(value) && value?.length === 0) || value === undefined) {
        return false;
      }
      return true;
    }
  ),
  brand_image: yup.mixed().test(
    "check_image",
    "Required",
    function (value) {
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
      if (value && !allowedFormats.includes(value.type)) {
        return this.createError({ message: "Only JPEG, PNG, and JPG formats are allowed" });
      }
      return true;
    }
  )
})

const loomisScheduleForm = yup.object().shape({
  pickup_date: yup.string().required("Required"),
  // pickup_email: yup
  //   .string()
  //   .required("Required")
  //   .matches(
  //     /^[A-Z0-9._%+-]+@[A-Z0-9-]+(\.[A-Z]{2,4}){1,2}$/i,
  //     "Enter Valid Email"
  //   ),
  // pickup_address_1: yup.string().required("Required"),
  // pickup_address_2: yup.string().required("Required"),
  // pickup_city: yup.string().required("Required"),
  // pickup_location: yup.string().required("Required"),
  pickup_attention: yup.string().required("Required"),
  // pickup_name: yup.string().required("Required"),
  // pickup_phone: yup
  //   .string()
  //   .required("Required")
  //   .test("is-valid", "Enter Valid Phone Number", (value) => {
  //     const numericValue = parseInt(value.split("-").join(""));
  //     return !isNaN(numericValue) && numericValue.toString().length === 10;
  //   }),
  // pickup_postal_code: yup.string().required("Required"),
  // pickup_province: yup.string().required("Required"),
  ready_time: yup
    .string()
    .required("Ready Time is Required")
    .test("is-valid", "Enter Valid Date", (value) => {
      if (!value) {
        return false;
      }
      return true;
    }),
  closing_time: yup
    .string()
    .required("Closing Time is Required")
    .test("is-valid", "Enter Valid Date", (value) => {
      if (!value) {
        return false;
      }
      return true;
    }),
  // number_of_parcels: yup.string()
  //   .required("Required")
  //   .test("is-valid", "Must be greater than 0", (value) => {
  //     const numericValue = parseInt(value);
  //     return !isNaN(numericValue) && numericValue > 0;
  //   }),
  //  weight: yup.string()
  //    .required("Required")
  //    .test("is-valid", "Must be greater than 0", (value) => {
  //      const numericValue = parseInt(value);
  //      return !isNaN(numericValue) && numericValue > 0;
  //    }),
})

export {
  generalTabSchema,
  cancelPurchaseOrderSchema,
  purchaseOrderSchema,
  taxFormSchema,
  approveOrderSchema,
  productsTabSchema,
  categorySchema,
  supplierSchema,
  storeSettingsFSchema,
  recievedPoSchema,
  priceCategorySchema,
  customerSchema,
  customerTypeSchema,
  customerJobTypeSchema,
  PromotionProductSchema,
  PromotionGeneralSchema,
  priceGroupProductSchema,
  priceGroupGenralSchema,
  sendEmailSchema,
  addressSchema,
  ctsairpaySchema,
  orderPreparationSchema,
  orderAdditionalInfoSchema,
  customerEditSchema,
  cardAmountSchema,
  customerEmployeeSchema,
  customerDocumentSchema,
  PaymentSchema,
  creditSchema,
  orderrReturnSchema,
  customerTermSchema,
  SplitPaymentSchema,
  customerCreditSchema,
  orderPaymentTabSchema,
  employeeSchema,
  employeeEditSchema,
  billFormSchema,
  productSchema,
  resetPasswordSchema,
  verifyOTPSchema,
  kitchenTemplateSchema,
  drywallTemplateSchema,
  receivePaymentSchema,
  vehicleSchema,
  brandSchema,
  loomisScheduleForm
};

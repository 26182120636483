import * as yup from "yup";

const customerSchema = yup.object().shape({
  first_name: yup.string().required("Required"),
  // company_name: yup.string().required("Required"),
  phone_no_1: yup
    .string()
    .required("Required")
    .test("is-valid", "Enter Valid Phone Number", (value) => {
      const numericValue = parseInt(value.split("-").join(""));
      return !isNaN(numericValue) && numericValue.toString().length === 10;
    }),
    email: yup
    .string()
    .required('Email is required')
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,}$/i,
      'Enter a valid email'
    ),
  // email: yup
  //   .string()
  //   .test("is-valid-email", "Enter Valid Email", function (value) {
  //     if (value) {
  //       return yup
  //         .string()
  //         .matches(
  //           /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
  //           "Enter Valid Email"
  //         )
  //         .isValidSync(value);
  //     }
  //     return true;
  //   }),
  customer_type_id: yup.string().required("Required"),

  job_category_id: yup.string().required("Required"),
  // .test("is-valid-job_category_id", "Enter Valid Email", function (value) {
  //   console.log(this.parent, value, this.parent?.job_category_id);
  //   return true;
  // }),
  billing_address: yup
    .mixed()
    .test("required-if-shipping_same_billing", "Required", function (value) {
      const shippingSameBilling = this.parent.billing_address;
      if (shippingSameBilling === "") {
        return !!value;
      }
      return true;
    })
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
  shipping_same_billing: yup.string(),
  shipping_address: yup
    .mixed()
    .test("required-if-shipping_same_billing", "Required", function (value) {
      const shippingSameBilling = this.parent.shipping_same_billing;
      if (shippingSameBilling === "No") {
        return !!value;
      }
      return true;
    })
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => value !== true
    ),
});

const customerCreditSchema = yup.object().shape({
  request_credit: yup
    .number()
    .typeError('Required') 
    .required('Required') 
    .test(
      'is-greater-than-zero',
      'Must be greater than zero',
      value => value > 0
    ),
  term: yup.string()
  .required("Required")
  .test('val-above-zero', 'Value must be greater than zero', (value) => {
  return value && parseFloat(value) > 0;
  }),
  authorized_person: yup.string().required("Required"),
});

const creditSchema = yup.object().shape({
  approved_credit: yup
    .string()
    .required("Required")
    .test(
      "is-valid",
      "Approved amount should not be greater than Request credit",
      function (value) {
        if (value > this.parent?.request_credit) {
          return this.createError({
            message: `Approved amount should not be greater than Request credit $ ${Number(
              this.parent?.request_credit
            ).toFixed(2)}`,
          });
        }
        return true;
      }
    ),
  approved_person: yup.string().required("Required"),
});

const customerTermSchema = yup.object().shape({
  term: yup
  .mixed()
  .test('is-zero', 'Required', function(value) {
    return value !== 0 && value !== '';
  })
  .required('Required')
  });


const customerEditSchema = yup.object().shape({
  first_name: yup.string().required("Required"),
  email: yup
    .string()
    .test("is-valid-email", "Enter Valid Email", function (value) {
      if (value) {
        return yup
          .string()
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,}$/i,
            "Enter Valid Email"
          )
          .isValidSync(value);
      }
      return true;
    }),
  phone_no_1: yup
    .string()
    .required("Required")
    .test("is-valid", "Enter Valid Phone Number", (value) => {
      const numericValue = parseInt(value.split("-").join(""));
      return !isNaN(numericValue) && numericValue.toString().length === 10;
    }),
  customer_type_id: yup.string().required("Required"),
  job_category_id: yup.string().required("Required"),
  billing_address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => value !== true
    )
    .strict(),
  shipping_same_billing: yup.string(),
  shipping_address: yup
    .mixed()
    .test("required-if-shipping_same_billing", "Required", function (value) {
      const shippingSameBilling = this.parent.shipping_same_billing;
      if (shippingSameBilling === "No") {
        return !!value;
      }
      return true;
    })
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => value !== true
    ),
});

const customerEmployeeSchema = yup.object().shape({
  user_name: yup.string().required("Required"),
  user_phone: yup
    .string()
    .required("Required")
    .test("is-valid", "Enter Valid Phone Number", (value) => {
      const numericValue = parseInt(value.split("-").join(""));
      return !isNaN(numericValue) && numericValue.toString().length === 10;
    }),
});

const customerDocumentSchema = yup.object().shape({
  documents: yup
    .array()
    .required("Required")
    .min(1, "At least one document is required"),
});

const customerTypeSchema = yup.object().shape({
  type_name: yup.string().required("Required"),
});

const customerJobTypeSchema = yup.object().shape({
  job_cat_name: yup.string().required("Required"),
});

const addressSchema = yup.object().shape({
  address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => value !== true
    )
    .strict(),
  shipping_same_billing: yup.string(),
  saddress: yup
    .mixed()
    .test("required-if-shipping_same_billing", "Required", function (value) {
      const shippingSameBilling = this.parent.shipping_same_billing;
      if (shippingSameBilling === "No") {
        return !!value;
      }
      return true;
    })
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => value !== true
    )
    .strict(),
});

const LeaveTypeSchema = yup.object().shape({
  reason: yup.string().required("Required"),
  date: yup.array().of(yup.string().required("Required")).required("Required"),
});

export {
  customerSchema,
  customerEditSchema,
  customerCreditSchema,
  creditSchema,
  customerTermSchema,
  customerEmployeeSchema,
  customerDocumentSchema,
  customerTypeSchema,
  customerJobTypeSchema,
  addressSchema,
  LeaveTypeSchema
};

import { createSlice } from '@reduxjs/toolkit'
import { createVehicle, deleteVehicle, editVehicle, vehicleDrop, vehicleGenerateNo, vehicleHistories, vehicleList, vehicleSingleView } from 'redux/service/menuService/vehicleService';


const initialState = {
  vehicleListRes: { data: [], isFetching: false, error: null },
  createVehicleRes: { data: [], isFetching: false, error: null },
  vehicleSingleViewRes: { data: [], isFetching: false, error: null },
  editVehicleRes: { data: [], isFetching: false, error: null },
  deleteVehicleRes: { data: [], isFetching: false, error: null },
  vehicleGenerateNoRes: { data: [], isFetching: false, error: null },
  vehicleHistoriesRes:  { data: [], isFetching: false, error: null },
  vehicleDropHistoriesRes:  { data: [], isFetching: false, error: null }
}

export const vehicleSlice = createSlice({
  name: 'vehicleSlice',
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: vehicleList, name: "vehicleListRes" },
      { api: vehicleGenerateNo, name: "vehicleGenerateNoRes" },
      { api: createVehicle, name: "createVehicleRes" },
      { api: vehicleSingleView, name: "vehicleSingleViewRes" },
      { api: editVehicle, name: "editVehicleRes" },
      { api: deleteVehicle, name: "deleteVehicleRes" },
      {api: vehicleHistories, name: "vehicleHistoriesRes"},
      {api: vehicleDrop, name: "vehicleDropHistoriesRes"}
    ];
    asyncActionCases.forEach((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { vehicleDispatch } = vehicleSlice.actions
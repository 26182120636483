import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "utils/api";

// vehicle single view
export const vehicleGenerateNo = createAsyncThunk(
  'vehicle-no',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/vehicleNo`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// vehicle List
export const vehicleList = createAsyncThunk(
  'vehicle-list',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/vehicles`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// vehicle single view
export const vehicleSingleView = createAsyncThunk(
  'vehicle-single-view',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/vehicle/${bodyParams.id}`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// vehicle create
export const createVehicle = createAsyncThunk(
  'create-vehicle',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.postForm(`/vehicle`, bodyParams);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// vehicle edit
export const editVehicle = createAsyncThunk(
  'update-vehicle',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.postForm(`/vehicle/${bodyParams.id}`, bodyParams.formData);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// vehicle delete
export const deleteVehicle = createAsyncThunk(
  'delete-vehicle',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/vehicle/${bodyParams}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

///vehicle Access Histories

export const vehicleHistories = createAsyncThunk(
  'vehicle-histories',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/vehicleAccessHistories/${bodyParams.id}`, { params: bodyParams });
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)

export const vehicleDrop = createAsyncThunk(
  'vehicle-drop-histories',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.postForm(`/vehicleDropHistory/${bodyParams.id}`, { ...bodyParams });
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)


import {
  loginTitleImage,
  loginlogoImage,
  Logo,
  dloginTitleImage,
  dloginlogoImage,
  dLogo,
  companyLogo1,
  companyLogo2
} from "../helpers/images";


const logoConfig = {
  CTS: {
    loginLogo: {
      src: loginlogoImage,
      width: 200,
      height: "auto"
    },
    titleLogo: {
      src: loginTitleImage,
      width: "auto",
      height: "auto"
    },
    dashboardLogo: {
      src: Logo,
      width: "80px",
      height: "auto"
    },
    dashboardIcon: {
      src: companyLogo1,
      width: '53px',
      height: "auto"
    }
  },
  DURHAM: {
    loginLogo: {
      src: dloginlogoImage,
      width: 70,
      height: "auto"
    },
    titleLogo: {
      src: dloginTitleImage,
      width: 100,
      height: "auto"
    },
    dashboardLogo: {
      src: dLogo,
      width: "45px",
      height: "auto"
    },
    dashboardIcon: {
      src: companyLogo2,
      width: "53px",
      height: "auto"
    }
  }
};

export function getLogo(url, type) {
  if (logoConfig[url] && logoConfig[url][type]) {
    return logoConfig[url][type];
  } else {
    return true;
  }
}

export function authLogo(url) {
  return getLogo(url, "loginLogo");
}

export function authTitleLogo(url) {
  return getLogo(url, "titleLogo");
}

export function dashboardLogo(url) {
  return getLogo(url, "dashboardLogo");
}

export function dashboardIcon(url) {
  return getLogo(url, "dashboardIcon");
}

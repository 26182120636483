import * as yup from "yup";
const generalTabSchema = yup.object().shape({
  name: yup.string().required("Product Name Required"),
  category_id: yup.number().required("Category Required"),
  unit_id: yup
    .array()
    .test("required-if-inventory", "Required", function (value) {
      return value?.length > 0 && !!value;
    }),
  // unit_quantity: yup.string().required("Required"),
  created_by: yup.string().required("Required"),
  order_type: yup.string().required("Required"),
  status: yup.string().required("Required"),
  display_location: yup.string().required("Required"),
  is_inventory: yup.string(),
  // min_quantity: yup
  //   .string()
  //   .test("required-if-inventory", "Required", function (value) {
  //     const isInventory = this.parent.is_inventory;
  //     return isInventory === "yes" ? !!value : true;
  //   }),
  // max_quantity: yup
  //   .string()
  //   .test(
  //     "is-greater-than-min",
  //     "Max must be greater than Min",
  //     function (value) {
  //       const { min_quantity } = this.parent;
  //       if (min_quantity && value) {
  //         return parseInt(value) >= parseInt(min_quantity);
  //       }
  //       return true;
  //     }
  //   )
  //   .test("required-if-inventory", "Required", function (value) {
  //     const isInventory = this.parent.is_inventory;
  //     return isInventory === "yes" ? !!value : true;
  //   }),
  // stock_quantity: yup
  //   .string()
  //   .test("required-if-inventory", "Required", function (value) {
  //     const isInventory = this.parent.is_inventory;
  //     return isInventory === "yes" ? !!value : true;
  //   }),
  min_quantity: yup.number()
  .required('Min quantity is required')
  .nullable()
  .typeError('Min quantity must be a number')
  .test('is-greater', 'Min quantity must be less than or equal to Max quantity', function (value) {
    const { max_quantity } = this.parent;
    if (max_quantity === null || max_quantity === undefined || value === null || value === undefined) {
        return true;
    }
    return Number(max_quantity) >= Number(value);
}),

max_quantity: yup.number()
  .required('Max quantity is required')
  .nullable()
  .typeError('Max quantity must be a number')
});
export default generalTabSchema;

import { createSlice } from "@reduxjs/toolkit";
import { clear } from "@testing-library/user-event/dist/clear";
import { createAsyncActionHandlers } from "helpers/asyncActionHandler";
import {
  createOrder,
  orderAdditionalInfo,
  orderFinalTotal,
  orderList,
  createOrderPreparation,
  orderProductSpecialOffer,
  orderSingle,
  tempOrderList,
  tempOrderSingleDelete,
  tempOrderView,
  temporderCreate,
  temporderDelete,
  temporderUpdate,
  viewAllCustomerCredit,
  createOrderProcess,
  createOrderDispatch,
  createOrderCompleted,
  orderdispatchList,
  createOrderInTransit,
  tempAdditionalSingleView,
  backOrderProcessing,
  backOrderPreparation,
  backOrderDispatch,
  listBackOrderApi,
  orderCompleteSingleView,
  singleBackOrderApi,
  temporderCustomerChange,
  cancelOrderApi,
  orderShipingAdressChange,
  checkCustomerOpenOrder,
  compeleteOrderList,
  deliveryStatusList,
  lowStockOrderSingleView,
  lowStockOrderList,
  cancelBackOrder,
  allOrderList,
  dailySheet,
  orderCarryOut,
  dailySheetComments,
  dailySheetSupplier,
  updateDelTemp,
  purchaseDailySheet,
  orderPrepareSingleView,
  transactionList,
  tempOrderSingle,
  viewcancelorder,
  cancelorderslist,
  listpageAdditinalInfo,
  deliveryDistanceView,
  deliveryDistanceCreate,
  unsucessfullorderslist,
  unsucessfullordersidlist,
} from "redux/service/menuService/orderService";
import { orderReturn } from "redux/service/menuService/returnOrderService";

const initialState = {
  orderListRes: { data: [], isFetching: true, error: null },
  dailySheetRes: { data: [], isFetching: true, error: null },
  tempOrderViewRes: { data: [], isFetching: true, error: null },
  viewAllCustomerCreditRes: { data: [], isFetching: true, error: null },
  temporderCreateRes: { data: [], isFetching: true, error: null },
  temporderDeleteRes: { data: [], isFetching: true, error: null },
  temporderUpdateRes: { data: [], isFetching: true, error: null },
  orderProductSpecialOfferRes: { data: [], isFetching: false, error: null },
  orderAdditionalInfoRes: { data: [], isFetching: false, error: null },
  createOrderRes: { data: [], isFetching: false, error: null },
  tempOrderListRes: { data: [], isFetching: false, error: null },
  tempOrderSingleDeleteRes: { data: [], isFetching: false, error: null },
  orderSingleRes: { data: [], isFetching: true, error: null },
  orderFinalTotalRes: { data: [], isFetching: false, error: null },
  orderPreparationRes: { data: [], isFetching: true, error: null },
  createOrderProcessRes: { data: [], isFetching: true, error: null },
  createOrderDispatchRes: { data: [], isFetching: true, error: null },
  createOrderCompletedRes: { data: [], isFetching: true, error: null },
  orderdispatchListRes: { data: [], isFetching: true, error: null },
  createOrderInTransitRes: { data: [], isFetching: true, error: null },
  tempAdditionalSingleViewRes: { data: [], isFetching: true, error: null },
  backOrderDispatchRes: { data: [], isFetching: true, error: null },
  backOrderPreparationRes: { data: [], isFetching: true, error: null },
  backOrderProcessingRes: { data: [], isFetching: true, error: null },
  listBackOrderApiRes: { data: [], isFetching: true, error: null },
  orderCompleteSingleViewRes: { data: [], isFetching: true, error: null },
  singleBackOrderApiRes: { data: [], isFetching: true, error: null },
  temporderCustomerChangeRes: { data: [], isFetching: true, error: null },
  cancelOrderApiRes: { data: [], isFetching: true, error: null },
  orderReturnRes: { data: [], isFetching: false, error: null },
  orderShipingAdressChangeRes: { data: [], isFetching: true, error: null },
  checkCustomerOpenOrderRes: { data: [], isFetching: true, error: null },
  lowStockOrderListRes: { data: [], isFetching: true, error: null },
  lowStockOrderSingleViewRes: { data: [], isFetching: true, error: null },
  cancelBackOrderRes: { data: [], isFetching: true, error: null },
  orderCarryOutRes: { data: [], isFetching: true, error: null },
  dailySheetCommentsRes: { data: [], isFetching: true, error: null },
  dailySheetSupplierRes: { data: [], isFetching: true, error: null },
  purchaseDailySheetRes: { data: [], isFetching: true, error: null },
  orderPrepareSingleViewRes: { data: [], isFetching: true, error: null },
  updateDelTempRes: { data: [], isFetching: true, error: null },
  tempOrderSingleRes: { data: [], isFetching: true, error: null },
  transactionListRes: { data: [], isFetching: true, error: null },
  viewcancelorderRes: { data: [], isFetching: true, error: null },
  cancelorderslistRes: { data: [], isFetching: true, error: null },
  listpageAdditinalInfoRes: { data: [], isFetching: true, error: null },
  deliveryDistanceViewRes: { data: [], isFetching: true, error: null },
  deliveryDistanceCreateRes: { data: [], isFetching: true, error: null },
  unsucessfullorderslistRes: { data: [], isFetching: true, error: null },
  unsucessfullordersidlistRes: {data: [], isFetching: true, error: null}
};

export const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {
    clearInitialValue: (state) => {
      state.viewAllCustomerCreditRes = initialState.viewAllCustomerCreditRes;
    },
  },
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: orderList, name: "orderListRes" },
      { api: dailySheet, name: "dailySheetRes" },
      { api: tempOrderView, name: "tempOrderViewRes" },
      { api: viewAllCustomerCredit, name: "viewAllCustomerCreditRes" },
      { api: temporderCreate, name: "temporderCreateRes" },
      { api: temporderDelete, name: "temporderDeleteRes" },
      { api: temporderUpdate, name: "temporderUpdateRes" },
      { api: orderProductSpecialOffer, name: "orderProductSpecialOfferRes" },
      { api: orderAdditionalInfo, name: "orderAdditionalInfoRes" },
      { api: createOrder, name: "createOrderRes" },
      { api: tempOrderList, name: "tempOrderListRes" },
      { api: tempOrderSingleDelete, name: "tempOrderSingleDeleteRes" },
      { api: orderSingle, name: "orderSingleRes" },
      { api: orderFinalTotal, name: "orderFinalTotalRes" },
      { api: createOrderPreparation, name: "orderPreparationRes" },
      { api: createOrderProcess, name: "createOrderProcessRes" },
      { api: createOrderDispatch, name: "createOrderDispatchRes" },
      { api: createOrderCompleted, name: "createOrderCompletedRes" },
      { api: orderdispatchList, name: "orderdispatchListRes" },
      { api: createOrderInTransit, name: "createOrderInTransitRes" },
      { api: tempAdditionalSingleView, name: "tempAdditionalSingleViewRes" },
      { api: backOrderProcessing, name: "backOrderProcessingRes" },
      { api: backOrderPreparation, name: "backOrderPreparationRes" },
      { api: backOrderDispatch, name: "backOrderDispatchRes" },
      { api: listBackOrderApi, name: "listBackOrderApiRes" },
      { api: orderCompleteSingleView, name: "orderCompleteSingleViewRes" },
      { api: singleBackOrderApi, name: "singleBackOrderApiRes" },
      { api: temporderCustomerChange, name: "temporderCustomerChangeRes" },
      { api: cancelOrderApi, name: "cancelOrderApiRes" },
      { api: orderShipingAdressChange, name: "orderShipingAdressChangeRes" },
      { api: checkCustomerOpenOrder, name: "checkCustomerOpenOrderRes" },
      { api: compeleteOrderList, name: "orderListRes" },
      { api: deliveryStatusList, name: "orderListRes" },
      { api: allOrderList, name: "orderListRes" },
      { api: lowStockOrderList, name: "lowStockOrderListRes" },
      { api: lowStockOrderSingleView, name: "lowStockOrderSingleViewRes" },
      { api: cancelBackOrder, name: "cancelBackOrderRes" },
      { api: orderCarryOut, name: "orderCarryOutRes" },
      { api: dailySheetComments, name: "dailySheetCommentsRes" },
      { api: dailySheetSupplier, name: "dailySheetSupplierRes" },
      { api: purchaseDailySheet, name: "purchaseDailySheetRes" },
      { api: orderPrepareSingleView, name: "orderPrepareSingleViewRes" },
      { api: updateDelTemp, name: "updateDelTempRes" },
      { api: tempOrderSingle, name: "tempOrderSingleRes" },
      { api: transactionList, name: "transactionListRes" },
      { api: viewcancelorder, name: "viewcancelorderRes" },
      { api: cancelorderslist, name: "cancelorderslistRes" },
      { api: listpageAdditinalInfo, name: "listpageAdditinalInfoRes" },
      { api: orderReturn, name: "orderReturnRes" },
      { api: deliveryDistanceCreate, name: "deliveryDistanceCreateRes" },
      { api: deliveryDistanceView, name: "deliveryDistanceViewRes" },
       {api: unsucessfullorderslist, name: "unsucessfullorderslistRes"},
       {api: unsucessfullordersidlist, name: "unsucessfullordersidlistRes"}
    ];
    asyncActionCases?.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { orderDispatch, clearInitialValue } = orderSlice.actions;

import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

// customer-list

export const customerList = createAsyncThunk(
  "customer-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customers`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const customerSaleList = createAsyncThunk(
  "customer-sale-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customersales`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customerId-getfrom-store
export const customerGetById = createAsyncThunk(
  "customer-byId",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customer_id/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-create
export const customerCreate = createAsyncThunk(
  "customer-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/customer`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-update
export const customerUpdate = createAsyncThunk(
  "customer-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.patch(
        `/customer/${bodyParams.id}`,
        bodyParams,
        {
          headers: {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-view
export const customerSingleView = createAsyncThunk(
  "customer-singleview",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customer/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-type-create
export const customerTypeCreate = createAsyncThunk(
  "customer-type-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/customer-type`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//job-category-create
export const jobCategoryCreate = createAsyncThunk(
  "job-category-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/job-category`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// customer-delete
export const customerDelete = createAsyncThunk(
  "customer-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`customer/${bodyParams.id}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// customer-termuse
export const customerTermUse = createAsyncThunk(
  "customer-termuse",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `termenabledisable/${bodyParams.id}`,
        bodyParams
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-receivepayment-view
export const receivepaymentSingleView = createAsyncThunk(
  "customer-receivepayment-singleview",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/viewsinglereceivepayment/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-overpayment-view
export const overpaymentSingleView = createAsyncThunk(
  "customer-overpayment-singleview",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/viewsingleoverpayment/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-unapplied-view
export const unappliedSingleView = createAsyncThunk(
  "customer-unapplied-singleview",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/viewsingleunapplied/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-creditmemo-view
export const creditmemoSingleView = createAsyncThunk(
  "customer-creditmemo-singleview",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/viewsinglecreditmemo/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const customerRefundCreditMemoCreate = createAsyncThunk(
  "refundcreditmemo-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/refundcreditmemo`, bodyParams, {});
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-pricinggroup
export const customerPricinggroup = createAsyncThunk(
  "customer-pricinggroup",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/getpricinggroupwithcustomertype/${bodyParams}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-pricinggroup
export const customerRebateHistory = createAsyncThunk(
  "customer-rebate-history",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `customers/rebate-history/${bodyParams}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-pricinggroup
export const customerRebateUpdate = createAsyncThunk(
  "customer-rebate-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `customers/rebate-cashback`, bodyParams
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//customer-pricinggroup
export const customerCartList = createAsyncThunk(
  "customer-cart-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `customer/carts/${bodyParams}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//Customer Cart Checkout

export const CustomerCartCheckout = createAsyncThunk(
  "customer-cart-checkout",
  async(bodyParams, thunkAPI) => {
    try{
     const response = await API.post(`/createcartorder`, bodyParams)
     return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)